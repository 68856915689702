import { sendGAEvent, sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';

import type { TrackingCategory } from 'src/tracking/types';
import type { TrackingContext } from 'src/types/TrackingContext';

export interface MembershipBannerTrackingContext extends TrackingContext {
  trackingCategory?: TrackingCategory;
}

export const trackImpression = ({
  trackingCategory,
  trackingContentType,
  trackingPosition,
  trackingTitle,
}: MembershipBannerTrackingContext) => {
  sendGAEvent({
    event: 'custom_event',
    event_action: 'Impression',
    event_category: `${trackingPosition} | ${trackingContentType}`,
    event_label: `${trackingPosition} | ${trackingTitle}`.toLowerCase(),
    event_name: 'view_content',
    content_position: `${trackingPosition}`,
    content_type: `${trackingContentType}|${trackingTitle}`.toLowerCase(),
    membership_banner_location: `${trackingCategory}`.toLowerCase(),
  });
};

export const trackLinkClick = (
  e: React.MouseEvent<HTMLAnchorElement>,
  {
    trackingCategory,
    trackingContentType,
    trackingPosition,
    trackingTitle,
  }: MembershipBannerTrackingContext,
) => {
  sendGALinkClickEvent(e, {
    event: 'custom_event',
    event_action: 'CTA click',
    event_category: `${trackingPosition} | ${trackingContentType}`,
    event_label: `${trackingPosition} | ${trackingTitle}`.toLowerCase(),
    event_name: 'select_content',
    event_details: 'cta click',
    content_type: `${trackingContentType}|${trackingTitle}`.toLowerCase(),
    content_position: `${trackingPosition}`,
    membership_banner_location: `${trackingCategory}`.toLowerCase(),
  });
};
