import React from 'react';
import type { MembershipBannerProps } from '@noths/polaris-client-ribbons-design-system';
import { MembershipBanner } from '@noths/polaris-client-ribbons-design-system';

import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import { trackImpression, trackLinkClick } from 'src/tracking/components/membershipBanner';
import type { TrackingCategory } from 'src/tracking/types';
import type { TrackingContext } from 'src/types/TrackingContext';
import * as styles from './MembershipBannerSection.styles';

export interface MembershipBannerSectionProps extends MembershipBannerProps {
  trackingCategory?: TrackingCategory;
  trackingPosition?: number;
  trackingTitle: string;
}

export const MembershipBannerSection = ({
  buttonHref,
  buttonText,
  copy,
  heading,
  trackingCategory,
  trackingPosition,
  trackingTitle,
}: MembershipBannerSectionProps) => {
  const trackingContext: TrackingContext = {
    trackingPosition,
    trackingTitle,
    trackingContentType: 'Membership banner',
    trackingCategory,
  };

  return (
    <SectionContainer columnsXL={10} maxWidth="full" wrapperStyles={styles.wrapper}>
      <MembershipBanner
        buttonHref={buttonHref}
        buttonText={buttonText}
        copy={copy}
        heading={heading}
        onButtonClick={(e) => trackLinkClick(e, trackingContext)}
        onVisible={() => trackImpression(trackingContext)}
      />
    </SectionContainer>
  );
};
